// To avoid detecting changes if month and date has preceeding "0" 
function normalizeDate(value) {
    if (typeof value === 'string') {
        const dateMatch = value.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
        if (dateMatch) {
            const [, year, month, day] = dateMatch;
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }
    }
    return value;
}

// To properly check and compare specially arrays
function isEqual(a, b) {
    // Handle null/undefined and false cases
    if ((a == null && b === false) || (b == null && a === false)) {
        return true;
    }

    // Handle strict equality for primitives and same references
    if (a === b) return true;

    // Handle arrays
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.length === b.length && a.every((val, index) => isEqual(val, b[index]));
    }

    // Handle objects
    if (typeof a === 'object' && typeof b === 'object' && a !== null && b !== null) {
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);

        // Ensure both objects have the same keys
        if (keysA.length !== keysB.length) return false;

        // Ensure values for all keys are deeply equal
        return keysA.every(key => keysB.includes(key) && isEqual(a[key], b[key]));
    }

    return false;
}



export const updatedFieldChecker = (oldData, newData) => {
    let updated = {};

    Object.keys(oldData).forEach(key => {
        // Deconstruct "address" object to properly do a comparison
        if (key === "address") {
            if (oldData.hasOwnProperty("address") && newData.hasOwnProperty("address")) {
                const oldAddress = oldData.address;
                const newAddress = newData.address;
        
                const differences = {};
        
                const compareObjects = (oldObj, newObj, path = "") => {
                    for (const prop in { ...oldObj, ...newObj }) {
                        const oldValue = oldObj[prop];
                        const newValue = newObj[prop];
                        const currentPath = path ? `${path}.${prop}` : prop;
        
                        if (typeof oldValue === "object" && oldValue !== null && 
                            typeof newValue === "object" && newValue !== null) {
                            compareObjects(oldValue, newValue, currentPath);
                        } else if (oldValue !== newValue) {
                            differences[currentPath] = { oldValue, newValue };
                        }
                    }
                };
        
                compareObjects(oldAddress, newAddress);
        
                if (Object.keys(differences).length > 0) {
                    updated[key] = differences;
                }
            }
        }

        // Filter status since "clinic" has a boolean status while other user types have string status
        else if(key === "status") {
            if((typeof(oldData.status) === 'string' && typeof(newData.status) === 'string' && oldData.status !== newData.status) || (oldData.status === 'Active' && typeof(newData.status) === 'boolean' && newData.status)) {
                updated[key] = { oldValue: oldData.status, newValue: (newData.status || oldData.status == "Active") ? 'Active' : 'Inactive' };
            }

        } else {
            if (newData.hasOwnProperty(key)){
                const oldValue = normalizeDate(oldData[key]);
                const newValue = normalizeDate(newData[key]);

                if (!isEqual(oldValue, newValue)) {
                    updated[key] = { oldValue: oldData[key], newValue: newData[key] };
                }
            }
        }
    });

    return updated;
}
